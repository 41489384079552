body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.bg-black {
  background-color: #000;
}

@font-face {
  font-family: "Geo Sans Light";
  src: local("Geo Sans Light"), url(./fonts/geosanslight.woff) format("woff"), url(./fonts/geosanslight.ttf) format("truetype");
}

.valdilana-font {
  font-family: "Geo Sans Light", sans-serif;
}

.fingerCursor {
  cursor: pointer;
}

* {
  scrollbar-width: thin;
  scrollbar-color: rgb(0, 0, 0) black;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 1px;
}

*::-webkit-scrollbar-track {
  background: black;
}

*::-webkit-scrollbar-thumb {
  background-color: rgb(0, 0, 0);
  border-radius: 0px;
  border: 1px solid black;
}

.hero4 {
  background-image: url(../public/vlHero4.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.swatch-image {
  width: 200px;
  height: auto;
  clip-path: polygon(
    5% 0%,
    7.5% 2.5%,
    10% 0%,
    12.5% 2.5%,
    15% 0%,
    17.5% 2.5%,
    20% 0%,
    22.5% 2.5%,
    25% 0%,
    27.5% 2.5%,
    30% 0%,
    32.5% 2.5%,
    35% 0%,
    37.5% 2.5%,
    40% 0%,
    42.5% 2.5%,
    45% 0%,
    47.5% 2.5%,
    50% 0%,
    52.5% 2.5%,
    55% 0%,
    57.5% 2.5%,
    60% 0%,
    62.5% 2.5%,
    65% 0%,
    67.5% 2.5%,
    70% 0%,
    72.5% 2.5%,
    75% 0%,
    77.5% 2.5%,
    80% 0%,
    82.5% 2.5%,
    85% 0%,
    87.5% 2.5%,
    90% 0%,
    92.5% 2.5%,
    95% 0%,
    97.5% 2.5%,

    100% 5%,
    97.5% 9%,
    100% 13%,
    97.5% 17%,
    100% 21%,
    97.5% 25%,
    100% 29%,
    97.5% 33%,
    100% 37%,
    97.5% 41%,
    100% 45%,
    97.5% 49%,
    100% 53%,
    97.5% 57%,
    100% 61%,
    97.5% 65%,
    100% 69%,
    97.5% 73%,
    100% 77%,
    97.5% 81%,
    100% 85%,
    97.5% 89%,
    100% 93%,
    97.5% 97%,

    95% 100%,
    92.5% 97.5%,
    90% 100%,
    87.5% 97.5%,
    85% 100%,
    82.5% 97.5%,
    80% 100%,
    77.5% 97.5%,
    75% 100%,
    72.5% 97.5%,
    70% 100%,
    67.5% 97.5%,
    65% 100%,
    62.5% 97.5%,
    60% 100%,
    57.5% 97.5%,
    55% 100%,
    52.5% 97.5%,
    50% 100%,
    47.5% 97.5%,
    45% 100%,
    42.5% 97.5%,
    40% 100%,
    37.5% 97.5%,
    35% 100%,
    32.5% 97.5%,
    30% 100%,
    27.5% 97.5%,
    25% 100%,
    22.5% 97.5%,
    20% 100%,
    17.5% 97.5%,
    15% 100%,
    12.5% 97.5%,
    10% 100%,
    7.5% 97.5%,
    5% 100%,
    0% 95%,

    2.5% 91%,
    0% 87%,
    2.5% 83%,
    0% 79%,
    2.5% 75%,
    0% 71%,
    2.5% 67%,
    0% 63%,
    2.5% 59%,
    0% 55%,
    2.5% 51%,
    0% 47%,
    2.5% 43%,
    0% 39%,
    2.5% 35%,
    0% 31%,
    2.5% 27%,
    0% 23%,
    2.5% 19%,
    0% 15%,
    2.5% 11%,
    0% 7%,
    2.5% 3%
  );
}
